@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Fredoka+One&family=IBM+Plex+Serif:wght@300&family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  border: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --para-color: #ffffffc7;
}

::-webkit-scrollbar {
  display: none;
}

body {
  cursor: default;
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
  color: #fff;
  background: linear-gradient(#00504a, black);
}
td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  background: linear-gradient(black, rgba(0, 0, 0, 0.226));
}
tr {
  font-weight: 200;
  font-size: 13px;
  color: var(--para-color);
}
table {
  width: 100%;
}
input:focus {
  background: transparent;
}

/* action */
.redirect .desc {
  margin: 0 auto;
  padding-bottom: 20px;
}
.redirect a {
  color: var(--para-color);
}

.actionButton {
  margin: 0;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 4px;
}
.actionButton svg {
  color: white;
}

.actionButton:hover {
  opacity: 0.75;
}
/* drop */
.css-13cymwt-control {
  background-color: transparent !important;
  border-color: #3d8eaa !important;
  min-height: 32px !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: #3d8eaa !important;
}
.css-1xc3v61-indicatorContainer {
  color: #3d8eaa !important;
  padding: 4px 8px !important;
}
/**********HOME********/
#HOME {
  height: auto;
  background: linear-gradient(#00504a, black);
  overflow: hidden;
}

#home {
  height: 100%;
  position: relative;
  min-height: 100vh;
}

.right {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 0.2rem;
  left: 7rem;
  bottom: 3.5rem;
}

.right a {
  color: #64d0ff;
}

.right a:hover {
  color: #fff;
}

.right::after {
  content: "";
  width: 1px;
  height: 2rem;
  background-color: #64d0ff;
  z-index: 2;
  opacity: 1;
  display: block;
  margin: 0 auto;
}

.left {
  position: absolute;
  font-weight: lighter;
  right: 3rem;
  font-size: 15px;
  bottom: 7rem;
  transform: rotate(90deg);
}

.left a {
  color: #64d0ff;
}

.left:hover a {
  color: rgba(255, 255, 255, 0.722);
}

.box {
  margin: auto 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  padding: 20px;
}

.text {
  width: 50%;
  margin: 20px;
  margin-top: 80px;
  margin-bottom: 150px;
}

.text h1 {
  /* text-transform: capitalize; */
  font-size: 2.8vw;
  line-height: normal;
  margin: 10px 0px;
  font-family: auto;
}

.text p {
  /* font-family: auto; */
  color: var(--para-color);
  padding: 3px 0;
  font-size: 0.9vw;
}

.text span {
  /* text-transform: capitalize; */
  color: #64d0ff;
}

/* .video {
  width: 40%;
} */

.img {
  width: 40%;
}

.img img {
  width: 100%;
}

#video{
  width: 82%;
  height: 90%;
  border-radius: 10px;
}
@media (max-width: 670px) {
  #video{
    width: 100%;
    height: 100%;
  }
}

.btn {
  background: transparent;
  border: none;
  padding: 14px 40px;
  border: 1px solid #64d0ff9c;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.5s;
  font-size: 0.9vw;
  color: white;
}

.btn:hover {
  background-color: #64d0ff;
  font-weight: 400;
  border: 1px solid #00504a;
  color: black;
}

/*************************Navbar*******************************/

nav {
  background: rgb(0 61 56 / 30%);
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.link a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: #64d0ff;
  font-size: 1.1rem;
}

.link a:hover {
  background: rgba(0, 0, 0, 0.3);
}

.link a.active {
  background: rgba(0, 0, 0, 0.695);
  color: white;
}

/* ////////////////////   SERVICES */
#portfolio {
  background: linear-gradient(black, #00504a);
}

#head {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 40px;
}

#head .tittle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}

.tittle::after {
  content: "";
  width: 37px;
  height: 1.3px;
  background-color: #64d0ff;
}

.tittle h3 {
  color: #64d0ff;
}

.desc {
  text-align: center;
  font-size: 0.9vw;
  margin: 20px 0;
  color: var(--para-color);
}

.tittle h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 3.1rem;
  margin: 10px;
}

#products {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100vw;
}

.p_img img {
  width: 100%;
  border-radius: 5px;
}

.p_img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes {
  position: relative;
  border-radius: 7px;
  padding: 3px;
  width: 25%;
}

.box .img {
  animation: up-down 1s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* HOVER */
.show {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding: 0 4px;
  position: relative;
}

.show li {
  color: #64d0ff;
  font-size: 1.4vw;
}

.over li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  color: #64d0ff;
  font-size: 1.15rem;
}

.over li span {
  color: white;
  font-size: 0.79vw;
}

.hov {
  background: rgb(0 0 0 / 86%);
  position: absolute;
  bottom: 10px;
  width: 30%;
  height: 5.5%;
  right: 13px;
  transition: 0.4s;
  transition-property: all;
}

.over {
  width: 0;
  height: 0;
  top: 4px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.boxes {
  margin: 20px;
}

.boxes:hover .hov {
  width: 92%;
  height: 40%;
  padding: 10px;
}

.boxes:hover .over {
  transition: 0.8s;
  transition-property: all;
  display: initial;
  opacity: 1;
  visibility: visible;
}

.boxes:hover .over button {
  transition: 0.8s;
  transition-property: all;
  opacity: 1;
  visibility: visible;
}

.boxes:hover .show {
  display: none;
}

.show li img {
  width: 14px;
  margin: 3px;
  filter: invert(100%);
}
.list_over_under li svg {
  font-size: 1.23vw;
}

.over button {
  padding: 7px 2.7vw;
  background-color: #64d0ff;
  color: black;
  display: block;
  margin: 20px auto;
  border-radius: 3px;
  visibility: hidden;
  font-size: 0.9vw;
  opacity: 0;
  cursor: pointer;
}

.over button:hover {
  transition: 0.3s !important;
  background-color: #ef8616;
  color: white;
}

.list_over {
  display: flex;
  justify-content: space-between;
}

.list_over li img {
  width: 16px;
  margin: 3px;
  filter: invert(100%);
}

.list_over li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.list_over_under {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.list_over_under span {
  font-size: 11px;
}

.over p {
  margin: 18px 0;
  font-size: 0.76vw;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn1 {
  padding: 18px 38px;
  border: 1px solid #64d0ff9c;
  border-radius: 10px;
  transform: translateY(30px);
}

.cv {
  z-index: 2;
  left: 50%;
  /* top: 20px; */
  display: flex;
  justify-content: center;

  /* position: absolute; */
  /* transform: translateX(-50%); */
  /* width: max-content;
    padding: 0.7rem 1.7rem;
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px); */
}

.talk {
  background: #64d0ff91;
  margin: 0 10px;
  border: none;
  border: 1px solid #00504a;
  transition: 0.3s;
  cursor: pointer;
}

.talk:hover {
  border: 1px solid #64d0ff9c;
  background-color: transparent;
}

.talk a {
  color: white;
}

.talk:hover a {
  color: #64d0ff;
}

.cv p {
  font-size: 1.05vw;
  font-weight: 400;
}

.download {
  transition: 0.5s;
  margin: 0 10px;
  cursor: pointer;
}

.download:hover {
  background-color: #64d0ff97;
  border: 1px solid #64d0ff70;
}

.download:hover p {
  color: white;
}

.btn1 {
  color: #64d0ff;
}

#footer {
  /* display: block; */
  text-align: center;
  padding-top: 20px;
}

#footer p {
  font-size: 0.8rem;
  color: white;
  font-family: emoji;
}

/*************************Contact ***************/
#contact {
  background: linear-gradient(#00504a, black);
}

.head_text {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head_text h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #64d0ff;
}

.head_text h5 {
  font-weight: 400;
}

.head_text::after {
  content: "";
  height: 1.4px;
  width: 50px;
  background-color: #64d0ff;
}

.contact__option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: 10px 0px;
  border-radius: 10px;
  background: #21847c36;
  backdrop-filter: blur(20px);
  transition: 0.5s;
}

.contact__option:hover {
  /* border: 1px solid #64d0ff61; */
  background: #00000056;
}

.contact_option {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 23vw;
}

.contact_icons {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.contact__option a {
  color: #64d0ff;
  font-size: 14px;
}

.contact__option h4 {
  font-weight: 100;
  font-size: 13px;
}

.contact__option h5 {
  font-size: 11px;
  font-weight: 400;
}

.contact__option a:hover {
  color: #64d0ff8c;
}

/**************** FORM ****************/

.contact_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 37%;
  margin-top: 24px;
}

input,
textarea {
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  background: transparent;
  border: 1px solid #64d0ff9c;
  color: white;
  border-radius: 5px;
  resize: none;
}

.btn_form {
  margin-top: 10px;
}

/******************** verify_submit *************************/
.submit_verify_icons {
  color: #70b900;
  font-size: 0.9rem;
}

.verify_submit {
  display: flex;
  position: absolute;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  align-items: center;
  background-color: #00000057;
  padding: 10px 16px;
  padding-left: 13px;
  transform: translatey(3rem);
  border-radius: 7px;
  transition: 0.3s all ease;
}

.verify_submit p {
  margin: 0 5px;
  margin-right: 8px;
  font-size: 12px;
}

.cut {
  cursor: pointer;
  /* color: #00000056; */
}

.cut:hover {
  color: #ffffff91;
}

/********* About services ********/
#services {
  padding-bottom: 5rem;
  background: linear-gradient(black, #00504a);
  /* min-height: 100vh; */
}

.text_services {
  margin-bottom: 42px;
  width: auto;
}

.text_services h1 {
  font-size: 2vw;
}

.text_services h1::after {
  content: " |";
  /* background-color: #64d0ff; */
}

.head_services {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  flex-direction: column;
}

.head_services h2 {
  font-size: 1.65rem;
  font-weight: 600;
  color: #64d0ff;
}

.head_services h5 {
  font-weight: 400;
}

.head_services::after {
  content: "";
  height: 0.9px;
  width: 40px;
  background-color: #64d0ff;
}

/********************* Eabout ****************/

#eabout {
  background: linear-gradient(black, #00504a);
  padding-top: 2rem;
}

#intro {
  padding-bottom: 10rem;
}

#about {
  padding: 20px 0;
  display: flex;
  gap: 10vw;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 0 auto;
}

.my_profile {
  width: 40%;
  border-radius: 2rem;
  display: inline-block;
}

.me {
  width: 75%;
  border-radius: 2rem;
  overflow: hidden;
  /* transform: rotate(10deg); */
  transition: 0.35s;
  margin: auto auto;
  background: linear-gradient(45deg, transparent, #64d0ff, transparent);
  /* background-color: black; */
}

.me:hover {
  transform: rotate(0);
}

.me img {
  border-radius: 2rem;
  width: 98%;
}

.about_text {
  display: flex;
  gap: 2vw;
}

.about_boxes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  padding: 35px;
  margin: 10px 0px;
  border-radius: 10px;
  background: #21847c36;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 0.5s;
}
.about_boxes:hover {
  background: #21847c1f;
}

.eabout_icons {
  font-size: 1.45rem;
  color: #64d0ff;
}

.about_boxes p {
  position: relative;
  display: block;
  font-size: 12px;
  font-size: 0.75vw;
  color: var(--para-color);
}

.about_boxes h5 {
  font-size: 0.87vw;
  font-weight: 500;
}

.about_left {
  width: 45%;
}

.about_desc p {
  font-size: 0.9vw;
  margin: 10px 0;
  color: var(--para-color);
  display: flex;
  flex-direction: column;
}

.btn_about {
  margin-top: 8px;
}

.about_head_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.about_head_text h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #64d0ff;
}

.about_head_text h5 {
  font-weight: 400;
}

.about_head_text::after {
  content: "";
  height: 1px;
  width: 40px;
  background-color: #64d0ff;
}

/************** eabout skill box**********/

.about_head_text_skill h2 {
  margin-bottom: 6px;
}

.about_head_text_skill::after {
  width: 50px;
}

.skill_box_list {
  background: #00000094;
  padding: 2.3vw;
  border-radius: 2vw;
  width: 35%;
  transition: all 0.5s ease;
}

.skill_head {
  text-align: center;
}

.skill_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5vw;
  flex-wrap: wrap;
}

.skill_list li {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5vw;
  margin-top: 25px;
}

.skill_list small {
  font-size: 1vw;
  font-weight: 500;
}

.skill_list p {
  font-size: 0.9vw;
  font-weight: 400;
  color: rgb(249 248 248 / 78%);
}

.skill_list {
  display: flex;
  gap: 4vw;
  align-items: flex-start;
  margin: 2vw 0;
  justify-content: center;
  margin-top: 1.5vw;
}

.first_skill_list,
.second_skill_list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.skill_verify_icons {
  color: #64d0ff;
  font-size: 0.9vw;
  margin-top: 0.4vw;
}

.skill_head h4 {
  font-size: 1.6vw;
  color: #64d0ff;
  font-weight: 500;
}

.skill_box_list:hover {
  background: #00000060;
}

.right_left_about {
  right: 5vw;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: -58rem;
}

.right_left_about::after {
  content: "";
  height: 30px;
  width: 1px;
  background-color: #64d0ff;
  margin: 0 auto;
}

.right_left_about a {
  color: #64d0ff;
}

.right_left_about a:hover {
  color: white;
}

.cp_icons {
  font-size: 1.2rem;
}

#skills {
  padding-bottom: 5rem;
}

/*back-color */

/****************** Home media queire***********************/

/************** About feature***************/

.feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.feature_icons {
  color: #64d0ff;
  font-size: 18px;
}

.feature p {
  font-size: 14px;
}

.set_feature {
  padding-top: 1.35rem;
  padding-bottom: 0;
  display: flex;
  /* justify-content: space-between; */
  /* gap: 1rem; */
  align-items: flex-start;
  flex-wrap: wrap;
  border-radius: 2rem;
}
.set_feature li {
  margin-right: 20px;
  margin-bottom: 8px;
}

.box_feature {
  justify-content: space-between;
  gap: 3.8rem;
  width: 85%;
  margin: 0 auto;
}

/****************** Home media queire***********************/
/* @media screen and (max-width:1280px) {
    nav {
        bottom: unset;
        top: 35.5rem;
    }
} */

@media screen and (max-width: 1026px) {
  /* nav {
        bottom: 2rem;
        top: unset;
    } */

  .desc {
    font-size: 1.25vw;
  }
}
/* 
@media screen and (max-height:600px) {
    nav {
        bottom: unset;
        top: 31.5rem;
    }
} */
@media screen and (max-width: 1300px) {
  .right {
    left: 4.5rem;
    bottom: 1.3rem;
  }
  .left {
    right: 1rem;
    bottom: 4rem;
  }
}

@media screen and (max-width: 1080px) {
  /* contact*/
  input,
  textarea {
    font-size: 1.45vw;
  }

  /* HOVER */
  .show li {
    font-size: 1.8vw;
  }
  .over li span {
    color: white;
    font-size: 1.1vw;
  }
  .list_over_under li svg {
    font-size: 1.75vw;
  }
  .over button {
    margin: 10px auto;
    font-size: 1.1vw;
  }
  .over p {
    margin: 18px 0;
    font-size: 1.1vw;
    text-align: center;
  }

  /* nav*/

  /* nav {
        bottom: unset;
        top: 34.5rem;
    } */

  #HOME {
    height: auto;
  }

  .text h1 {
    font-size: 4.5vw;
  }

  .box {
    margin: 0 auto;
  }
  .box_feature {
    width: 95%;
  }

  .text p {
    font-size: 1.2vw;
  }

  .btn {
    font-size: 1.3vw;
  }

  .cv p {
    font-size: 1.35vw;
  }

  .left {
    right: 0rem;
    bottom: 3rem;
  }

  .right {
    left: 3.4rem;
    bottom: 0rem;
  }

  /* eabout*/
  #intro {
    height: auto;
  }

  .about_boxes h5 {
    font-size: 1.35vw;
    font-weight: 500;
  }

  .about_boxes p {
    font-size: 1.22vw;
  }

  .about_desc p {
    font-size: 1.25vw;
    text-align: center;
  }

  .about_left {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #about {
    flex-direction: column;
  }

  .my_profile {
    margin-left: 0px;
    width: 60%;
  }

  /* skill */
  #skills {
    height: auto;
    padding-bottom: 3.4rem;
  }

  .skill_verify_icons {
    font-size: 1.07vw;
    margin-top: 0.3vw;
  }

  .right_left_about {
    right: 1vw;
    bottom: -68rem;
  }

  .skill_head h4 {
    font-size: 1.8vw;
  }

  .skill_list small {
    font-size: 1.4vw;
  }

  .skill_list p {
    font-size: 1.2vw;
  }

  .skill_box_list {
    padding: 2.789vw;
    width: 43%;
  }
  .skill_box {
    gap: 5vw;
  }
  /*  services-about*/
  .text_services h1 {
    font-size: 3vw;
  }

  #services {
    height: auto;
    padding: 0;
  }

  /* contact */
  .contact__option h4 {
    font-weight: 500;
    font-size: 1.4vw;
  }

  .contact__option h5 {
    font-size: 1.2vw;
  }

  .contact__option a {
    font-size: 1.5vw;
  }

  /* last work*/
  .boxes {
    width: 29.5%;
    margin: 14px;
  }

  #products {
    padding: 0;
  }
}
@media screen and (max-width: 1290px) {
  .boxes:hover .hov {
    height: 48%;
  }
  #home {
    min-height: auto;
  }
}
@media screen and (max-width: 769px) {
  
  /* contact */
  input,
  textarea {
    font-size: 1.7vw;
  }
  /* HOVER */
  .show li {
    font-size: 2.4vw;
  }
  .over li span {
    font-size: 1.6vw;
  }
  .list_over_under li svg {
    font-size: 2.2vw;
  }
  .over button {
    padding: 7px 3.4vw;
    font-size: 1.7vw;
  }
  .over p {
    margin: 18px 0;
    font-size: 1.7vw;
    text-align: center;
  }

  /* .boxes:hover .hov {
    height: 51%;
  } */

  /* last work*/
  .boxes {
    width: 43%;
    margin: 10px;
  }

  /*footer*/
  #footer p {
    font-size: 2.1vw;
  }
}

.direct_contact {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-bottom: 4.5rem;
}

@media screen and (max-width: 658px) {
  .direct_contact {
    margin-bottom: 0;
  }
  .boxes:hover .hov {
    height: 51%;
  }
  .set_feature {
    justify-content: center;
  }
  #intro {
    margin-bottom: 0rem;
    padding-bottom: 5rem;
  }

  /* contact */
  input,
  textarea {
    font-size: 2.1vw;
  }

  /* nav */
  nav {
    gap: 0;
  }

  /* home */
  .cv p {
    font-size: 1.65vw;
  }

  .right {
    left: 2.4rem;
    bottom: 7.5rem;
  }

  .box {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
  }

  .text {
    width: 65%;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 100px;
  }
  .text h1 {
    font-size: 6.5vw;
  }
  .text p {
    font-size: 1.7vw;
  }
  .btn {
    font-size: 1.65vw;
  }
  .img {
    padding-top: 50px;
  }
  .left {
    bottom: 9rem;
  }

  /* eabout*/
  .my_profile {
    width: 53%;
  }
  .about_boxes h5 {
    font-size: 2.35vw;
  }
  .about_boxes p {
    font-size: 1.7vw;
  }
  .about_desc p {
    font-size: 1.85vw;
  }

  /* services */
  #services {
    padding-bottom: 3rem;
  }

  /* services about*/
  .box_feature .left_box {
    width: 92%;
  }
  .text_services {
    margin-bottom: 42px;
    width: auto;
  }
  .feature {
    justify-content: center;
    gap: 3rem;
  }
  .text_services h1 {
    font-size: 4.65vw;
  }
  .box_feature .img {
    width: 50%;
  }
  /* eabout skill*/
  .skill_box_list {
    padding: 4.789vw 2vw;
    width: 63%;
  }
  .skill_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .skill_head h4 {
    font-size: 3vw;
  }
  .skill_list small {
    font-size: 2.1vw;
  }
  .skill_list p {
    font-size: 1.7vw;
  }
  .skill_verify_icons {
    font-size: 1.8vw;
    margin-top: 0.8vw;
  }
  /* port folio*/
  .desc {
    font-size: 1.75vw;
  }
  /* contact*/
  .contact_option {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2vw;
    padding-bottom: 5rem;
  }
  .contact__option h4 {
    font-size: 1.9vw;
  }
  .contact__option h5 {
    font-size: 1.62vw;
  }
  .contact__option a {
    font-size: 1.85vw;
  }
  /* .direct_contact {
    display: flex;
    gap: 1rem;
    flex-direction: row;
  } */
  .contact_form {
    width: 80%;
    margin-top: 39px;
  }

  .verify_submit {
    transform: translatey(18.5rem);
  }
}

@media screen and (max-width: 476px) {
  #head {
    padding-bottom: 20px;
  }
  /* right */
  .right_left_about {
    right: 1vw;
    bottom: -78rem;
  }
  /* contact */
  input,
  textarea {
    font-size: 2.5vw;
  }
  /* HOVER */
  .show li {
    font-size: 4.3vw;
  }

  .over li span {
    font-size: 2.5vw;
  }
  .list_over_under li svg {
    font-size: 3.5vw;
  }
  .over button {
    padding: 7px 7.4vw;
    font-size: 2.6vw;
  }
  .over p {
    margin: 18px 0;
    font-size: 2.6vw;
    text-align: center;
  }
  .boxes:hover .hov {
    height: 45%;
  }
  /* home */
  .cv p {
    font-size: 2.1vw;
  }
  .btn1 {
    padding: 14px 33px;
  }
  .text p {
    font-size: 2.1vw;
  }
  .img {
    width: 65%;
  }
  .text h1 {
    font-size: 8.4vw;
  }
  .text {
    width: 82%;
  }
  .right {
    left: 1.4rem;
    bottom: 5.5rem;
  }
  .left {
    bottom: 9rem;
    right: -1rem;
  }
  .btn {
    font-size: 2.4vw;
  }

  /* eabout */

  .my_profile {
    width: 90%;
  }

  .about_text {
    display: flex;
    gap: 4vw;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .about_desc p {
    font-size: 2.2vw;
    margin-top: 2rem;
    display: block;
  }
  .about_boxes h5 {
    font-size: 3vw;
  }
  .about_boxes p {
    font-size: 2.1vw;
  }
  .about_left {
    width: 80vw;
    text-align: center;
  }
  .about_boxes {
    margin: 0;
  }

  /* skill */
  .skill_box_list {
    padding: 5.789vw 6vw;
    width: 81%;
  }
  .skill_head h4 {
    font-size: 4vw;
  }
  .skill_list small {
    font-size: 2.8vw;
  }
  .skill_list p {
    font-size: 2.4vw;
  }
  .skill_verify_icons {
    font-size: 2.6vw;
    margin-top: 0.8vw;
  }
  .skill_list li {
    gap: 1vw;
  }

  /*  service about*/
  .box_feature .img {
    width: 65%;
  }
  .text_services h1 {
    font-size: 6vw;
  }

  .text p {
    font-size: 2.5vw;
  }
  .text_services {
    width: auto;
    margin-top: 0;
  }

  /* portfolio */
  .boxes {
    width: 75%;
    margin: 10px;
  }
  .desc {
    font-size: 2.5vw;
    width: 80%;
  }

  /* contact */
  .direct_contact {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact__option {
    margin: 0;
  }
  .contact__option h4 {
    font-size: 2.9vw;
  }
  .contact__option h5 {
    font-size: 2.3vw;
  }
  .contact__option a {
    font-size: 2.8vw;
  }
  /* footer */
  #footer p {
    font-size: 2.6vw;
  }
}
@media screen and (max-width: 290px) {
  .right .right_social_icons {
    font-size: 0.8rem !important;
  }
}
@media screen and (max-width: 380px) {
  /* cp*/
  .cp_icons {
    font-size: 1.04rem;
  }
  .right .right_social_icons {
    font-size: 4vw;
  }
  .left a {
    font-size: 4vw;
  }
  .link a {
    padding: 0.7em;
    font-size: 4.5vw;
  }
  .right {
    left: 1rem;
    gap: 0;
  }
  .direct_contact {
    gap: 0.5rem;
  }
  input,
  textarea {
    padding: 13px;
    font-size: 3.1vw;
  }
}
/* @media screen and (min-height:728px){
    #contact{
        height: 65rem;
    }
 } */
